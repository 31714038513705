<template>
    <v-container fluid class="pt-0">
        <v-card flat class="px-3 mt-1 d-flex flex-column align-center justify-center" :height="height">
            <div v-if="!isLoading">
                <span class="d-flex align-center justify-center pt-3 font-weight-bold">Weekly Kiosk Utilization Snapshot
                    ({{
                        displayDate }})</span>
            </div>
            <div id="chart-container-summary"></div>
            <v-progress-circular indeterminate color="primary" size="25" v-if="isLoading"></v-progress-circular>
            <v-card-title class="pa-0 text-caption d-flex align-end justify-end" v-if="!isLoading">
                <span>Last Synced: {{ getCurrentDate }} | <a @click="syncData()">Sync Data</a></span>
            </v-card-title>
        </v-card>
    </v-container>
</template>
<script>
import * as d3 from 'd3';
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
    name: "summaryBarChart",
    data() {
        return {
            height: window.innerHeight / 1.8,
            isLoading: true,
            date: new Date(),
            timeoutId: null
        }
    },
    computed: {
        ...mapGetters(["summaryBarChart", "displayDate"]),
        getCurrentDate() {
            return moment(this.date).format('lll')
        },
    },
    watch: {
        // summaryBarChart() {
        //     this.createSummaryBarChart()
        // }
    },
    methods: {
        createSummaryBarChart() {
            this.timeoutId = setTimeout(() => { this.syncData(); console.log('Timeout triggered') }, 300000)
            d3.select('#chart-container-summary').selectAll('*').remove();
            const width = window.innerWidth - 350;
            const height = this.height - 65;
            const margin = { top: 25, right: 30, bottom: 80, left: 30 };

            this.summaryBarChart.sort((a, b) => a.id.localeCompare(b.id));
            const categories = this.summaryBarChart.map(d => d.category);

            const svg = d3.select('#chart-container-summary')
                .append('svg')
                .attr('width', width)
                .attr('height', height);

            const xScale = d3.scaleBand()
                .domain(categories)
                .range([margin.left, width - margin.right])
                .padding(0.5);

            const yScale = d3.scaleLinear()
                .domain([0, d3.max(this.summaryBarChart, d => d.value)])
                .nice()
                .range([height - margin.bottom, margin.top]);

            // Append bars
            svg.selectAll('rect')
                .data(this.summaryBarChart)
                .enter()
                .append('rect')
                .attr('x', d => xScale(d.category))
                .attr('y', d => yScale(d.value))
                .attr('width', xScale.bandwidth())
                .attr('height', d => height - margin.bottom - yScale(d.value))
                .attr('fill', '#0d3b66');

            // Append labels to bars
            svg.selectAll('.bar-label')
                .data(this.summaryBarChart)
                .enter()
                .append('text')
                .text(d => (d.value !== 0 ? d.value : ''))
                .attr('x', d => xScale(d.category) + xScale.bandwidth() / 2)
                .attr('y', d => yScale(d.value) - 10) // Adjust vertical position as needed
                .attr('text-anchor', 'middle')
                .attr('class', 'bar-label');

            // Append x-axis
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(xScale));

            // Rotate x-axis labels
            svg.selectAll('.x-axis text')
                .attr('transform', 'rotate(-30)')
                .style('text-anchor', 'end');

            // Append y-axis
            svg.append('g')
                .attr('class', 'y-axis')
                .attr('transform', `translate(${margin.left},0)`)
                .call(d3.axisLeft(yScale));

            // Append y-axis label
            svg.append('text')
                .attr('transform', 'rotate(-90)')
                .attr('x', -height / 2)
                .attr('y', margin.left - 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '10px')
                .text('User Sessions');

        },
        syncData() {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId)
            }
            this.isLoading = true
            this.$store.dispatch('getKioskUtilizationSummary').then(() => {
                const timeoutSync = setTimeout(() => {
                    this.createSummaryBarChart()
                    this.date = new Date()
                    this.isLoading = false
                }, 3000)
            })
        }
    },
    mounted() {
        console.log('COming here at mounted? ')
        const timeoutMounted = setTimeout(() => {
            this.createSummaryBarChart()
            //this.date = new Date()
            this.isLoading = false
        }, 3000)
    }
}
</script>