<template>
  <v-row class="fill-height" no-gutters>
    <!-- <v-col cols="12" v-if="!isSuperAdmin && !isAdmin && !isClientAdmin" class="d-flex align-center justify-center">
      <telepresence-notes></telepresence-notes>
    </v-col> -->
    <v-col cols="12">
      <kiosk-list></kiosk-list>
    </v-col>
    <v-col cols="12">
      <summary-bar-chart></summary-bar-chart>
    </v-col>
  </v-row>
</template>

<script>

import { mapGetters } from 'vuex'
import KioskList from '../components/dashboard/admin/homeUI/kioskList.vue';
import TelepresenceNotes from '../components/telepresenceNotes.vue';
import summaryBarChart from '../components/graphs/summaryBarChart.vue';
export default {
  name: 'home',
  data() {
    return {
      selectedKiosk: ''
    }
  },
  components: {
    TelepresenceNotes,
    KioskList,
    summaryBarChart
  },
  computed: {
    ...mapGetters(["userProfile", "getDeviceSpec", "allRegisteredKiosks", "isSuperAdmin", "isAdmin", "isClientAdmin"]),
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
  },
  mounted() {
    this.$store.dispatch("getKioskUtilizationSummary")
  }
}
</script>
<style>
.cardBorder {
  border-width: 1px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 5px !important;
}
</style>